import { Helmet } from "react-helmet";
import Layout from "../components/layout";

function IndexPage({ children }) {
  return (
    <Layout>
      <Helmet>
        <title>Nathan Pasko</title>
        <meta
          name="description"
          content="Hello from Nathan Pasko — designer, developer, writer, artist, musician. Check out Nathan's work from virtual games and toys to experimental music and soundtracks to web apps and blogs. Come say hi via email!"
        />
      </Helmet>
      {children}
    </Layout>
  );
}

export default IndexPage;
